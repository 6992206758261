import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import Circle from '../../resources/images/profile_page/circle.svg';
import { Container } from 'styled-bootstrap-grid';
import theme from '../../styles/theme';

const HeroContainer = styled(Container)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    padding: 157px 35px 50px 35px;
    background: url(${Circle}) no-repeat;
    background-size: 330px;
    background-position-x: calc(100% + 40px);
    background-position-y: 110px;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-top: 240px;
        padding-bottom: 135px;
        background-position-x: calc(100% - 230px);
        background-position-y: 100px;
        background-size: 582px;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        padding-left: 164px;
        padding-right: 164px;
    }
`;

const Title = styled.div`
    ${fonts.CircularMedium};
    color: ${theme.colors.white};
    font-size: 50px;
    line-height: 50px;
    letter-spacing: -2px;
    margin-bottom: 37px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 90px;
        line-height: 100px;
        letter-spacing: -5px;
        margin-bottom: 39px;
        max-width: 735px;
    }
`;
const Intro = styled.div`
    ${fonts.MaisonNeueLight};
    font-size: 16px;
    line-height: 20px;
    color: ${theme.colors.white};
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 28px;
        max-width: 604px;
    }
`;

const Hero = ({title, intro}) => {
    return (
        <HeroContainer >
            <Title>{title}</Title>
            <Intro>{intro}</Intro>
        </HeroContainer>
    )
}

export default Hero;
