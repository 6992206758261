import React, { Component } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Hero from '../components/profile/Hero';
import Intro from '../components/profile/Intro';
import About from '../components/profile/About';
import Clients from '../components/core/Clients';
import Connect from '../components/core/Connect';
import get from "lodash/get";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import theme from '../styles/theme';
import {Fade} from "react-reveal";
import IntersectionObserver from "../components/core/IntersectionObserver";

const LayoutStyled = styled(Layout)`
  margin: 0;
`;

const BlackBackground = styled.div`
  width: 100%;
  background: ${theme.colors.black};
`;

const GreyBackground = styled.div`
  width: 100%;
  background: ${theme.colors.greyBackground};
`;

const WhiteSpace = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  height: 120px;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 210px;
  }
`;

class ProfilePage extends Component {
  state = {
    theme: "light"
  };

  changeLogo = (shouldSwitch) => {
    let theme = "light";

    if (shouldSwitch) {
      theme = "dark";
    }

    this.setState({
      theme: theme
    })
  };

  render() {
    const path = this.props.location.pathname;
    const profile = get(this, 'props.data.allContentfulProfile.edges')[0].node;
    const reviews = get(this, 'props.data.allContentfulReviews.edges');
    const logos = get(this, 'props.data.allContentfulClientLogo.edges');
    const {changeLogo} = this;

    return (
      <LayoutStyled colorScheme={this.state.theme}>
        <SEO title="Society Studio"
          description="" fullTitle={true}
          path={path} />
          <BlackBackground className="cursor-white">
              <Fade duration={750}>
                  <Hero title={profile.heroTitle} intro={documentToReactComponents(profile.intro.json)}/>
              </Fade>
              <Fade duration={750} delay={250}>
                  <Intro content={documentToReactComponents(profile.firstContentBlock.content.json)}
                         image={profile.firstContentBlock.image.fluid}/>
              </Fade>
              <Fade duration={750}>
                  <About content={documentToReactComponents(profile.secondContentBlock.content.json)}
                         image={profile.secondContentBlock.image.fluid}/>
              </Fade>
          </BlackBackground>
        <IntersectionObserver onChange={changeLogo}>
          <GreyBackground>
            <Clients reviews={reviews} logos={logos} type={'light'} text={'Some of the clients we\'ve had the pleasure of working with ...'} />
            <Connect content={profile.contactBlock.content}
                     type={'light'}
                     img={profile.contactBlock.image.fluid} />
          </GreyBackground>
        </IntersectionObserver>
        <WhiteSpace />
      </LayoutStyled>
    )
  }
};

export default ProfilePage;

export const profileQuery = graphql`
  query ProfileQuery {
    allContentfulReviews(sort: { fields: position, order: ASC }, limit: 4) {
      edges {
        node {
          id
          content {
            json
          }
          clientName
          companyPosition
        }
      }
    }
    allContentfulClientLogo(sort: { fields: position, order: ASC }) {
      edges {
        node {
          id
          image: image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulProfile(limit: 1) {
      edges {
        node {
          heroTitle
          intro {
            json
          }
          secondContentBlock {
            content {
              json
            }
            image: image {
              fluid(
                maxWidth: 1000
                quality: 60
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          firstContentBlock {
            content {
              json
            }
            image: image {
              fluid(
                maxWidth: 1000
                quality: 60
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          contactBlock {
            content {
              json
            }
            image: image {
              fluid(
                maxWidth: 1000
                quality: 60
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
