import React, {Component} from "react";
import styled from "styled-components";
import Slider from "react-slick/lib";
import fonts from '../../styles/fonts';
import theme from "../../styles/theme";
import { Col, Row } from 'styled-bootstrap-grid';

const SliderStyled = styled(Slider)`
  .slick-track {
  width: auto !important;
  }
  .slick-slide {
    height: 100%;
    left: 0 !important;
    width: 100%;
    display: none;
  }
  .slick-active {
    min-height: 200px;
    display: block;
  }
`;

const Quote = styled.div`
  @media(min-width: ${theme.breakpoints.medium}){
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    outline: none;
  }
`;

const QuoteText = styled.div`
  ${fonts.MaisonNeueLight};
  color: ${theme.colors.white};
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0;
  padding-bottom: 30px;
  outline: none;
  @media(min-width: ${theme.breakpoints.medium}) {
    max-width: 610px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 45px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    font-size: 26px;
    line-height: 39px;
  }
`;

const QuoteFrom = styled.div`
  ${fonts.MaisonNeueDemi};
  color: ${theme.colors.white};
  font-size: 16px;
  line-height: 19.2px;
  letter-spacing: 0;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-left: 30px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    font-size: 18px;
    line-height: 21.6px;
  }
`;

const CarouselDots = styled.div`
  display: ${props => props.desktop ? 'none' : 'flex'};
  flex-direction: row;
  margin-top: 10px;
  @media(min-width: ${theme.breakpoints.medium}){
    display: ${props => props.desktop ? 'flex' : 'none'};
    margin-top: 40px;
  }
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50px;
  margin-right: 15px;
  position: relative;
  cursor: pointer;
  background: ${theme.colors.white};
  :after {
    content: '';
    height: 4px;
    width: 4px;
    z-index: 50;
    background: ${props => props.active === true ? theme.colors.black : 'transparent'};
    border: 1px solid transparent;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: 0.2s;
  }
  &:hover {
    :after {
      background: rgba(0,0,0,0.8);
  }
  }
`;

const ClientsTitle = styled.div`
  ${fonts.CircularMedium};
  font-size: 35px;
  line-height: 42px;
  letter-spacing: 0;
  padding-right: 0;
  margin-bottom: 20px;
  color: ${props => props.type === 'dark' ? theme.colors.white : theme.colors.black};
  @media(min-width: ${theme.breakpoints.medium}){
    margin-bottom: 0;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    font-size: 62px;
    line-height: 76px;
    letter-spacing: -2px;
    max-width: ${props => props.type === 'light' ? '620px' : '480px'};
  }
`;

const CarouselContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
`;

class Testimonials extends Component {
  state = {
    slideIndex: 0,
  };

  changeSlide(index) {
        this.slider.slickGoTo(index)
  }

  render() {
    const { reviews, type, text } = this.props;
    const { changeSlide } = this;
    const { slideIndex } = this.state;

    const settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 7000,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        pauseOnHover: false,
        swipe: true,
        swipeToSlide: true,
        draggable: true,
        beforeChange: (current, next) => this.setState({ slideIndex: next }),
    };

    return (
        <CarouselContainer>
            <Row>
                <Col md={6}>
                    <ClientsTitle type={type}>{text}</ClientsTitle>
                    <CarouselDots desktop>
                        {reviews.map((review, index) =>
                            <StyledButton key={index} className="cursor-dot-white">
                                <Dot active={slideIndex === index}  onClick={changeSlide.bind(this, index)}/>
                            </StyledButton>
                        )}
                    </CarouselDots>
                </Col>
                <Col md={6}>
                    <SliderStyled ref={slider => (this.slider = slider)} {...settings}>
                            {reviews.map((review, index) => {
                                return (
                                    <div key={index}>
                                        <Quote>
                                            <QuoteText>{review.node.content.json['content'][0]['content'][0]['value']}</QuoteText>
                                            <QuoteFrom>{review.node.clientName} / {review.node.companyPosition}</QuoteFrom>
                                        </Quote>
                                    </div>
                                )
                            })}
                    </SliderStyled>
                    <CarouselDots>
                        {reviews.map((review, index) =>
                            <StyledButton key={index}>
                                <Dot active={slideIndex === index} onClick={changeSlide.bind(this, index)}/>
                            </StyledButton>
                        )}
                    </CarouselDots>
                </Col>
            </Row>
        </CarouselContainer>

    );
  }
}

export default Testimonials;
