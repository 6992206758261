import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import Circle from '../../resources/images/profile_page/grey-circle.svg';
import DarkCircle from '../../resources/images/profile_page/circle.svg';
import { Col, Row, Container } from 'styled-bootstrap-grid';
import Testimonials from '../home/Testimonials';
import theme from '../../styles/theme';
import {Fade} from "react-reveal";

const ClientsContainer = styled(Container)`
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  padding-top: 110px;
  background: ${ props => props.type === 'dark' ? `url(${DarkCircle}) no-repeat` : `url(${Circle}) no-repeat`};
  background-size: 330px;
  background-position-x: -35px;
  background-position-y: 110px;
  width: 100%;
  @media(min-width: ${theme.breakpoints.medium}) {
    margin: auto;
    padding-top: ${props => props.type === 'light' ? '226px' : '330px'};
    box-sizing: border-box;
    background: ${ props => props.type === 'dark' ? `url(${DarkCircle}) no-repeat` : `url(${Circle}) no-repeat`};
    background-size: 480px;
    background-position-x: 310px;
    background-position-y: ${props => props.type === 'light' ? '300px' : '390px'};
  }
  @media(min-width: ${theme.breakpoints.large}) {
      padding-left: 156px;
      padding-right: 156px;
  }
`;

const ClientsTitle = styled.div`
  ${fonts.CircularMedium};
  font-size: 35px;
  line-height: 42px;
  letter-spacing: 0;
  padding-right: 0;
  margin-bottom: 20px;
  color: ${props => props.type === 'dark' ? theme.colors.white : theme.colors.black};
  @media(min-width: ${theme.breakpoints.medium}){
    margin-bottom: 0;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    font-size: 62px;
    line-height: 76px;
    letter-spacing: -2px;
    max-width: ${props => props.type === 'light' ? '620px' : '480px'};
  }
`;

const ClientLogo = styled.div`
  height: auto;
  width: 100%;
  max-height: 100px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: ${props => props.isEven ? '20px' : '0'};
  margin-left: ${props => props.isEven ? '0' : '20px'};
  text-align: center;
  img {
    object-fit: contain;
    max-height: 50px;
    height: 100%;
    max-width: 120px;
  }
  @media(min-width: ${theme.breakpoints.medium}){
    margin: auto;
    max-height: ${props => props.type !== 'light' ? '220px' : '110px'};
    img {
      margin: auto 20px;
      min-height: 35px;
      max-height: ${props => props.type !== 'light' ? '220px' : '110px'};
      max-width: 180px;
      height: 100%;
    }
  }
`;

const ClientsList = styled.div`
  width: 100%;
  margin-top: ${props => props.type !== 'light' && '100px'};
  @media(min-width: ${theme.breakpoints.medium}) {
    margin-top: 100px;
  }
`;

const ColStyled = styled(Col)`
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.start ? 'flex-start' : 'center'};
  box-sizing: border-box;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: ${props => props.logos && '215px'};
    padding-left: ${props => props.start && '30px'};
  }
`;

const RowStyled = styled(Row)`
  display: ${props => props.type === 'dark' && 'none'};
  height: 100%;
`;

const Clients = (props) => {
    const { type, text, reviews, logos } = props;
    return (
        <ClientsContainer type={type} className={'dark'}>
            <Fade duration={750} delay={100}>
                <RowStyled>
                    {type !== 'light' ?
                        <Col md={12}>
                            <Testimonials reviews={reviews} type={type} text={text}/>
                        </Col>
                        :
                        <Col md={6}>
                            <ClientsTitle type={type}>{text}</ClientsTitle>
                        </Col>
                    }
                </RowStyled>
            </Fade>
            <ClientsList type={type}>
                <RowStyled>
                    {logos.map(({node}, index) => {
                        return (
                            <ColStyled xs={6} sm={6} md={4} lg={3} key={node.id} logos>
                                <Fade duration={750} delay={100}>
                                    <ClientLogo isEven={index % 2 === 0} type={type}>
                                        <img src={node.image.file.url} alt={"Client Logo"}/>
                                    </ClientLogo>
                                </Fade>
                            </ColStyled>
                        )
                    })}
                </RowStyled>
            </ClientsList>
        </ClientsContainer>
    )
};

export default Clients;
