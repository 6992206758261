import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import Img from 'gatsby-image';
import { Container } from 'styled-bootstrap-grid';
import theme from '../../styles/theme';

const AboutContainer = styled(Container)`
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  box-sizing: border-box;
  @media(min-width: ${theme.breakpoints.medium}) {
    margin: 107px auto auto auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    padding-left: 164px;
    padding-right: 164px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;
const AboutText = styled.div`
  ${fonts.MaisonNeueLight};
  padding-right: 0;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 50px;
  color: ${theme.colors.white};
  max-width: 487px;
  width: auto;
  @media(min-width: ${theme.breakpoints.medium}) { 
    font-size: 18px;
    line-height: 28px;
  }
  @media(min-width: ${theme.breakpoints.large}) { 
    padding-top: 100px;
    padding-right: 120px; 
  }
`;

const AboutImg = styled(Img)`
  width: 100%;
  margin-top: auto;
  margin-bottom: -60px;
  @media(min-width: ${theme.breakpoints.medium}) { 
    height: auto;
    max-width: 610px;
    margin-bottom: -90px;
  }
  @media(min-width: ${theme.breakpoints.large}) { 
    height: auto;
    width: 50%;
  }
`;

const About = ({image, content}) => {
    return (
        <AboutContainer >
            <AboutText>
              {content}
            </AboutText>
            <AboutImg fluid={image} />
        </AboutContainer>
    )
}

export default About;
