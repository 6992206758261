import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import Img from 'gatsby-image';
import { Container } from 'styled-bootstrap-grid';
import theme from '../../styles/theme';

const IntroContainer = styled(Container)`
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  @media(min-width: ${theme.breakpoints.medium}) {
    align-items: center;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    height: 710px;        
    flex-direction: row;
  }
`;

const IntroImg = styled(Img)`
  width: 100%;
  height: 210px;
  margin-top: 0px;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 640px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    width: 35%;
    height: calc(100% - 68px);
    object-fit: cover;
    margin-top: 68px;
  }
`;
const IntroBackground = styled.div`
  background: ${theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${fonts.CircularMedium};
  width: 100%;
  height: auto;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -1px;
  margin-bottom: 0;
  padding: 60px 35px 40px 35px;
  box-sizing: border-box;
  color: ${theme.colors.black};
  position: relative;
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -2px;
    color: ${theme.colors.black};
    padding: 60px 130px 60px 182px;
    box-sizing: border-box;
    margin-bottom: 68px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    height: calc(100% - 68px);
    width: 65%;
  }
`;
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`
const Intro = ({image, content}) => {
    return (
        <IntroContainer >
            <IntroImg fluid={image} />
            <IntroBackground>
              <Overlay />
              {content}
            </IntroBackground>
        </IntroContainer>
    )
};

export default Intro;



