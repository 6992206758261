import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import Img from 'gatsby-image';
import { Container } from 'styled-bootstrap-grid';
import theme from '../../styles/theme';
import {Fade} from "react-reveal";
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';

const options = {
  renderNode: {
    [INLINES. HYPERLINK]: (node, next) => {
      return <a href={node.data.uri} className="cursor-dot-white">{node.content[0].value}</a>
    }
  }
}

const ConnectContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  margin-top: 75px;
  a {
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    color: ${props => props.type === 'dark' ? `${theme.colors.white}` : `${theme.colors.black}`};
    border: none;
    outline: none;
    border-bottom: ${props => props.type === 'dark' ? `3px solid ${theme.colors.white}` : `3px solid ${theme.colors.black}`};
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      border: none;
      outline: none;
      box-shadow: none;
      border-bottom: ${props => props.type === 'dark' ? `3px solid ${theme.colors.white}` : `3px solid ${theme.colors.black}`};
    }
    @media(min-width: ${theme.breakpoints.medium}) {
      border-bottom: ${props => props.type === 'dark' ? `6px solid ${theme.colors.white}` : `6px solid ${theme.colors.black}`};
        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
          border: none;
          outline: none;
          box-shadow: none;
          border-bottom: ${props => props.type === 'dark' ? `6px solid ${theme.colors.white}` : `6px solid ${theme.colors.black}`};
        }
    }
  }
  @media(min-width: ${theme.breakpoints.medium}) {
    width: 100%;
    padding-right: 0;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 100px auto auto auto;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    padding-left: 186px;
  }
`;

const ConnectText = styled.div`
  ${fonts.CircularMedium};
  color: ${props => props.type === 'dark' ? `${theme.colors.white}` : `${theme.colors.black}`};
  z-index: 11;
  width: 100%;
  margin: 0;
  font-size: 35px;
  line-height: 42px;
  letter-spacing: -1px;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-top: 265px;
    padding-bottom: 40px;
    position: relative;
    margin-right: -335px;
    max-width: 442px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    font-size: 62px;
    line-height: 74.4px;
    letter-spacing: -2px;
    padding-bottom: 0;
    max-width: 910px;
    margin-right: -535px;
  }
`;

const ConnectImg = styled(Img)`
  width: 100%;
  margin-top: 75px;
  height: 216px;
  margin-bottom: -60px;
  @media(min-width: ${theme.breakpoints.medium}) {
    max-width: 1040px;
    width: 73%;
    z-index: 1;
    position: relative;
    margin-bottom: -85px;
    height: 500px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    height: 750px;
  }
`;

const ContainerConnect = styled.div`

`;

const Connect = (props) => {
    const {type, img, content} = props;
    return (
        <ContainerConnect className={'dark'}>
            <Fade duration={1000} delay={100}>
                <ConnectContainer type={type}>
                    <ConnectText type={type}>
                        {documentToReactComponents(content.json, options)}
                    </ConnectText>
                    <ConnectImg fluid={img} type={type}/>
                </ConnectContainer>
            </Fade>
        </ContainerConnect>
    )
};

export default Connect;
